import React, {useState} from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
//add firebase 
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import the missing functions
import axios from 'axios';

import { useTranslation } from "react-i18next";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDVsuHdp9TCqS7pIDQwa76Et4LVWBERrOw",
  authDomain: "mysecondproject-371903.firebaseapp.com",
  projectId: "mysecondproject-371903",
  storageBucket: "mysecondproject-371903.appspot.com",
  messagingSenderId: "147944213110",
  appId: "1:147944213110:web:b349c2613f739d7abfed57",
  measurementId: "G-G3D7FHZQRN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);



const itemData = [
    {
      img_gs: 'KakaoTalk_Photo_2024-07-18-15-12-33-001.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2FKakaoTalk_Photo_2024-07-18-15-12-33-001.jpeg?alt=media&token=2af5d9b4-6c80-468e-8750-6d7f9d2f840b',
      title: 'Breakfast',
    },
    {
      img_gs: 'KakaoTalk_Photo_2024-07-18-15-12-33-002.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2FKakaoTalk_Photo_2024-07-18-15-12-33-002.jpeg?alt=media&token=6b9f39b6-5774-47ab-99a1-c66fee6d2e30',
      title: 'Burger',
    },
    {
      img_gs: 'KakaoTalk_Photo_2024-07-18-15-12-33-003.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2FKakaoTalk_Photo_2024-07-18-15-12-33-003.jpeg?alt=media&token=a6600136-b410-4087-9278-d4bd28515feb',
      title: 'Camera',
    },  
    {
      img_gs: 'KakaoTalk_Photo_2024-07-18-15-12-33-007.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2FKakaoTalk_Photo_2024-07-18-15-12-33-007.jpeg?alt=media&token=9d21ad27-c030-4fab-9880-c40d4ee52dfd',
      title: 'Coffee',
      },
    ];
const DeviceImage = ({ onDataChange, onProgressChange, onClearMethod }) => {
    const [imageUrl, setImageUrl] = useState(); // State to store the uploaded image URL
    const [gsImageUrl, setGsImageUrl] = useState(); // State to store the uploaded image URL
    const [uploading, setUploading] = useState(false);
    const { t } = useTranslation();

    const clearMethod = ()=>{
      onClearMethod();
    }
    const sendDataToParent = (data, rag) => {
      const newData = data;
      onDataChange(newData, rag); // 콜백 함수 호출하여 데이터 전달
    };
    const sendProgressToParent = () => {
      // const newData = data;
       onProgressChange("pressed"); // 콜백 함수 호출하여 데이터 전달
      // setCalling("pressed");
     };
    //radiogroup에서 선택된 radio 버튼을 usestate변수에 저장하기


    //add function to upload image to gcs
    const handleImageUpload = (event) => {
      setUploading(true);
      setImageUrl(null);


      const file = event.target.files[0];
      const storageRef = ref(storage, `images/${file.name}`);
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          // Get the download URL of the uploaded image
          getDownloadURL(snapshot.ref)
            .then((url) => {
              // Update the state with the image URL
              setImageUrl(url);
              setGsImageUrl(file.name);
              console.log("Image uploaded successfully:", url);
            })
            .catch((error) => {
              console.error("Error getting download URL:", error);
            });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        }).finally(() => {
          setUploading(false);
        });
    };

    const handleClick = (image, img_gs) => {
        setImageUrl(image);
        setGsImageUrl(img_gs);

    }


async function getData() {
  try {
    //응답 성공
    clearMethod();

    //const response = await axios.get('https://34.54.185.40:80/',
    const response = await axios.get('https://dev-backend-app-4rb5q3coza-uc.a.run.app/device',

    {
      params: {
        image_url: gsImageUrl,
        lang : navigator.language,

//        model: model, 
      }
    
    });
    console.log("xmrrh respnse = :", response);
    if(response.data.data === undefined){
      console.log("response.data");
      sendDataToParent(response.data, "rag")
    }else{
      console.log("response.data.data")
    sendDataToParent(response.data.data);
    }
  } catch (error) {
    //응답 실패
    console.error(error);
  }
}
    const handleGetMethodClick = () => {
      sendProgressToParent();

        getData()
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Box style={{color:"blue"}} >{t('please-upload-web-clean')}</Box>
                    <br/>
                    <br/>
                    <input type="file" accept="image/jpg, image/jpeg" onChange={handleImageUpload} name="file" />

                </Grid>



                <Grid item xs={5}>
                {imageUrl && <Button variant="contained" onClick={() => {
                                    handleGetMethodClick();
                                }}>{t('search-btn-clean')}</Button>}
                </Grid>
                <Grid item xs={7}>
                
                <div className="col-md-6 offset-md-3">
          {/* Display the uploaded image if available */}
          {imageUrl && <img src={`${imageUrl}?w=800&h=500&fit=crop&auto=format&dpr=2 2x`}  height='320' minheight='320'   className="img-fluid" alt="Uploaded Recipe Image" />}
          {/* Otherwise, display the placeholder image */}
          {!imageUrl && !uploading && <Box style={{color:"red"}} >{t('please-upload-ask')}</Box>}
          {!imageUrl && uploading && <Box style={{color:"red"}} >{t('uploading')}</Box>}
        </div>
          
                </Grid>
                <Grid item xs={5}>
                    <ImageList sx={{ width: 400, height: 300 }} cols={2} rowHeight={145} gap={8}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                            <img
                                src={`${item.img}`}
                                loading="lazy"
                                style={{width: 195, height: 100}}
                                  onClick={() => {
                                    handleClick(item.img, item.img_gs);
                                }}
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>

            </Grid>
    </Box>
        );
}

export default DeviceImage;

import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from "react-i18next";
function Navbar() {
    const [click, setClick] = useState(false);


    const handleClick = () => setClick(!click) ;
    const closeMobileMenu = () => setClick(false);

    const { t } = useTranslation();
   // const { t, i18n } = useTranslation();



    return (
        <>
        <nav className = 'navbar'>
            <div className = 'navbar-container'>
                {/* 모바일버전에서 클릭하면 메뉴 보이도록 설정하는 것도 한다. (close Mobile Menu)는 다시 버튼 누르면 없어지고 생기고 하도록 한다.  */}
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    Gemini~ 
                    <i className='fab fa-typo3' />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className = {click ? 'fas fa-times' : 'fas fa-bars' } />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick = {closeMobileMenu}>
                            {t('app1')}
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/clean' className='nav-links' onClick = {closeMobileMenu}>
                        {t('app2')}
                        </Link>
                    </li>

                   
                </ul>
            </div>
        </nav>
        </>
    );
}

export default Navbar
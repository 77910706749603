import * as React from 'react';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import CardMedia from '@mui/material/CardMedia';

import Recipes from './components/Recipes';
import SourceImage from './components/SourceImage';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

function ResponsiveDrawer(props) {


  const [progress, setProgress] = useState("released");


  const [recipes, setRecipes] = useState([]);
  const [nofood, setNofood] = React.useState("");
  const [ragQuery, setRagQuery] = React.useState(false);

  const { t } = useTranslation();

  const handleClearRecipe = () => {
    setRecipes([]);
  };

  const handleProgressChange = (progress) => {
    console.log("progress=", progress);
    setProgress(progress);
  };
  const handleDataChange = (newData,rag ) => {
    
    console.log("newData:",newData);
    console.log("rag:",rag);
    setProgress("released")
    console.log("progress:",progress);
    setNofood("");

    if(rag === "rag"){
      setRagQuery(true);
      console.log("ragQuery:",ragQuery);
      try{
        const tt = JSON.parse(newData);
        console.log("tt=", tt);

        setRecipes(tt);
    }catch(e){
      
      setNofood("nofood");
      console.log("e=",e);
        
       // console.log("nofood=",nofood);
    }
    }else
    {
      setRagQuery(false);
      const regex = /```json([\s\S]*?)```/;
      //const regex = "```json\n(.*?)\n```";
      const match = newData.match(regex);
      console.log("match=", match);
      if (match) {
        const jsonString = match[1].trim();
        console.log("특고",jsonString);
        const tt = JSON.parse(jsonString);
        console.log("tt=", tt);
    
        setRecipes(tt);
      }else{
        try{
            const tt = JSON.parse(newData);
            console.log("tt=", tt);
  
            setRecipes(tt);
        }catch(e){
          
          setNofood("nofood");
          console.log("e=",e);
            
           // console.log("nofood=",nofood);
        }
      }
    }
    
  };



  return (
    <Box sx={{ display: 'flex' }}>
      
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} >
        <Container maxWidth="full">
          <SourceImage onDataChange={handleDataChange} onProgressChange={handleProgressChange} onClearRecipe = {handleClearRecipe}></SourceImage>
        </Container>
        {progress === "pressed" ?
        <Container maxWidth="full" >
          <Box sx={{ display: 'flex' }}>
            <CircularProgress sx={{ m: 'auto', display: 'block' }} />
          </Box>
        </Container>
        :
        (nofood  ? 
          <Box style={{color:"red"}} >{t('re-upload')}</Box>     
          :
          recipes &&
            <Container maxWidth="full">
              <Recipes recipes={recipes} ragQuery={ragQuery}></Recipes>
           </Container>
        )
        }
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;

import React, {useState} from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
//add firebase 
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import the missing functions
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import { useTranslation } from "react-i18next";


// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDVsuHdp9TCqS7pIDQwa76Et4LVWBERrOw",
  authDomain: "mysecondproject-371903.firebaseapp.com",
  projectId: "mysecondproject-371903",
  storageBucket: "mysecondproject-371903.appspot.com",
  messagingSenderId: "147944213110",
  appId: "1:147944213110:web:b349c2613f739d7abfed57",
  measurementId: "G-G3D7FHZQRN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);



const SourceImage = ({ onDataChange, onProgressChange, onClearRecipe }) => {
    const [imageUrl, setImageUrl] = useState(); // State to store the uploaded image URL
    const [gsImageUrl, setGsImageUrl] = useState(); // State to store the uploaded image URL
    const [model, setModel] = useState("gemini-1.5-pro-001"); 
    const [uploading, setUploading] = useState(false);
    const { t } = useTranslation();

    const clearRecipe = ()=>{
      onClearRecipe();
    }
    const sendDataToParent = (data, rag) => {
      const newData = data;
      onDataChange(newData, rag); // 콜백 함수 호출하여 데이터 전달
    };

    const sendProgressToParent = () => {
     // const newData = data;
      onProgressChange("pressed"); // 콜백 함수 호출하여 데이터 전달
     // setCalling("pressed");
    };
    //radiogroup에서 선택된 radio 버튼을 usestate변수에 저장하기
    const isAndroid = () => {
      console.log("isAndroid : ", navigator.userAgent);
      return /android/i.test(navigator.userAgent);
    }

    //add function to upload image to gcs
    const handleImageUpload = (event) => {
      setImageUrl(null);

      setUploading(true);
      const file = event.target.files[0];
      const storageRef = ref(storage, `images/${file.name}`);
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          // Get the download URL of the uploaded image
          getDownloadURL(snapshot.ref)
            .then((url) => {
              // Update the state with the image URL
              setImageUrl(url);
              setGsImageUrl(file.name);
              console.log("Image uploaded successfully:", url);
            })
            .catch((error) => {
              console.error("Error getting download URL:", error);
            });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        }).finally(() => {
          setUploading(false);
        });
    };




async function getData() {
  try {
    clearRecipe();
    //응답 성공
    console.log("model:", model);
    const response = await axios.get('https://dev-backend-app-4rb5q3coza-uc.a.run.app/',
    {
      params: {
        image_url: gsImageUrl,
        model: model, 
        lang : navigator.language,

      }
    
    });
    console.log("xmrrh respnse = :", response);
    if(response.data.data === undefined){
      console.log("response.data");
      sendDataToParent(response.data, "rag")
    }else{
      console.log("response.data.data")
    sendDataToParent(response.data.data);
    }
  } catch (error) {
    //응답 실패
    console.error(error);
  }
}
    const handleGetRecipeClick = () => {

        sendProgressToParent();

        getData()
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box style={{color:"blue"}} >{t('please-upload-mobile')}</Box>
                  {isAndroid() ?  <input type="file" accept="image/jpg, image/jpeg" onChange={handleImageUpload} name="file" capture="camera" /> : 
                  <input type="file" accept="image/jpg, image/jpeg" onChange={handleImageUpload} name="file" /*capture="environment"*/ />
                  }
                </Grid>

                <Grid item xs={12}>
                
                <div className="col-md-6 offset-md-3">
          {/* Display the uploaded image if available */}
          {imageUrl && <img src={`${imageUrl}?w=350&h=320&fit=crop&auto=format&dpr=2 2x`}  width='300' minwidth='300' height='280' minheight='280'   className="img-fluid" alt="Uploaded Recipe Image" />}
          {/* Otherwise, display the placeholder image */}
          {!imageUrl && !uploading && <Box style={{color:"red"}} >{t('please-upload-ask')}</Box>}
          {!imageUrl && uploading && <Box style={{color:"red"}} >{t('uploading')}</Box>}
        </div>
          
                </Grid>
                <Grid item xs={7}>
                {imageUrl &&  
                      <div>
              
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label"><b>{t('select-model')}</b></FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="gemini-1.5-pro-001"
                            name="radio-buttons-group"
                            onChange={(event) => setModel(event.target.value)}
                          >
                            <FormControlLabel value="gemini-1.5-flash-001" control={<Radio />} label="Gemini 1.5 Flash" />
                            <FormControlLabel value="gemini-1.5-pro-001" control={<Radio /> } label="Gemini 1.5 Pro" />
                            <FormControlLabel value="rag" control={<Radio />} label={t('model-rag')}  />
                          </RadioGroup>
                      </FormControl>
                      </div>
                                            
                }
                </Grid>
                <Grid item xs={5}>
                {imageUrl && <Button variant="contained" onClick={() => {
                                    handleGetRecipeClick();
                                }}>Get Recipe</Button>}
                </Grid>

            </Grid>
    </Box>
        );
}

export default SourceImage;

import React from 'react';


import Grid from '@mui/material/Unstable_Grid2';
import ReactMarkdown from "react-markdown";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


import { useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";


const MobileMethod = ({ method, ragQuery }) => {
  const imgRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (imgRef.current) {
      window.scrollTo({ top: imgRef.current.offsetTop, behavior: 'smooth' });
    }
  }, []);

  return (
    <Grid container spacing={10}  ref={imgRef}  paddingTop={5}>
      <Grid xs={12} >
       <Card elevation={5} >
       <CardContent>
       <Typography variant="body1" component="p">
            <ReactMarkdown>
              {method}
            </ReactMarkdown>
            </Typography>
            </CardContent>
        </Card>   
        </Grid>
        </Grid>
  );
};

export default MobileMethod;
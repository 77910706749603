import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Unstable_Grid2';

import Typography from '@mui/material/Typography';


import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

const Recipes = ({ recipes, ragQuery }) => {
  const { t } = useTranslation();

  const imgRef = useRef(null);
  useEffect(() => {
    if (imgRef.current) {
      window.scrollTo({ top: imgRef.current.offsetTop, behavior: 'smooth' });
    }
  }, []);
  return (

    
    <Grid container spacing={10} ref={imgRef} paddingTop={5}>
     
    {
    recipes.map((recipe, index) => (
        
              <Grid xs={6} key={index} >
                <Card  elevation={5}>

                  <CardHeader title={recipe.title} />
                  {/*<CardMedia
                      component="img"
                      alt="green iguana"
                      height="300"
                      image="https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c?w=164&h=164&fit=crop&auto=format&dpr=2 2x"
                    />
    */}
                 {!ragQuery &&  <CardContent>
                  <Typography variant="subtitle1" component="p">

                    <b>{t('main-ingre')}</b>  {recipe.main_ingredients} <br />
                    </Typography>  
                    <br />
                    <Typography variant="subtitle1" component="p">

                    <b>{t('sub-ingre')}</b>  {recipe.additional_ingredients} <br />
                    </Typography>  
                    <br />
                  <Typography variant="body1" component="p">

                    {recipe.recipe} <br />
                    </Typography>

                  </CardContent>
                  }
                  {ragQuery &&  <CardContent>
                  <Typography variant="subtitle1" component="p">

                    <b>Ingredient :</b>  {recipe.ingredient} <br />
                    </Typography>  
                    <br />
                  
                  <Typography variant="body1" component="p">

                  <b>Recipe : </b> {recipe.directions} <br />
                    </Typography>

                  </CardContent>
                  }
                  {/*
                  <CardActions>
                    <Button variant="contained" color="primary">
                      사진 보기
                    </Button>
                  </CardActions>
  */}
                  </Card>                
              </Grid>
              )
            )
  
  }
  </Grid>
  );
};

export default Recipes;
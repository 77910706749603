import React, {useState, useEffect} from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
//add firebase 
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import the missing functions
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { useTranslation } from "react-i18next";


// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDVsuHdp9TCqS7pIDQwa76Et4LVWBERrOw",
  authDomain: "mysecondproject-371903.firebaseapp.com",
  projectId: "mysecondproject-371903",
  storageBucket: "mysecondproject-371903.appspot.com",
  messagingSenderId: "147944213110",
  appId: "1:147944213110:web:b349c2613f739d7abfed57",
  measurementId: "G-G3D7FHZQRN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);



const itemData = [
    {
      img_gs: 'sample_001.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2Fsample_001.jpeg?alt=media&token=721577d0-7d23-4140-a638-243631923f62',
      title: 'Breakfast',
    },
    {
      img_gs: 'sample_002.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2Fsample_002.jpeg?alt=media&token=f0142c8f-9cce-4a5f-8188-d07079ad50fe',
      title: 'Burger',
    },
    {
      img_gs: 'sample_001.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2Fsample_001.jpeg?alt=media&token=721577d0-7d23-4140-a638-243631923f62',
      title: 'Breakfast',
    },
    {
      img_gs: 'sample_002.jpeg',
      img: 'https://firebasestorage.googleapis.com/v0/b/mysecondproject-371903.appspot.com/o/images%2Fsample_002.jpeg?alt=media&token=f0142c8f-9cce-4a5f-8188-d07079ad50fe',
      title: 'Burger',
    },
    ];
const SourceImage = ({ onDataChange, onProgressChange, onClearRecipe }) => {
    const [imageUrl, setImageUrl] = useState(); // State to store the uploaded image URL
    const [gsImageUrl, setGsImageUrl] = useState(); // State to store the uploaded image URL
    const [model, setModel] = useState("gemini-1.5-pro-001"); 
    const [uploading, setUploading] = useState(false);
    const { t } = useTranslation();

    const clearRecipe = ()=>{
      onClearRecipe();
    }
    const sendDataToParent = (data, rag) => {
      const newData = data;
      onDataChange(newData, rag); // 콜백 함수 호출하여 데이터 전달
    };
    const sendProgressToParent = () => {
      // const newData = data;
       onProgressChange("pressed"); // 콜백 함수 호출하여 데이터 전달
      // setCalling("pressed");
     };
    //radiogroup에서 선택된 radio 버튼을 usestate변수에 저장하기


    //add function to upload image to gcs
    const handleImageUpload = (event) => {
      setUploading(true);
      setImageUrl(null);


      const file = event.target.files[0];
      const storageRef = ref(storage, `images/${file.name}`);
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          // Get the download URL of the uploaded image
          getDownloadURL(snapshot.ref)
            .then((url) => {
              // Update the state with the image URL
              setImageUrl(url);
              setGsImageUrl(file.name);
              console.log("Image uploaded successfully:", url);
            })
            .catch((error) => {
              console.error("Error getting download URL:", error);
            });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        }).finally(() => {
          setUploading(false);
        });
    };

    const handleClick = (image, img_gs) => {
        setImageUrl(image);
        setGsImageUrl(img_gs);

    }

/*
    async function getImageURL() {
      try {
        const storageRef = ref(storage, `images/food.jpg`);

        const url = await getDownloadURL(storageRef);
        console.log('이미지 GS 경로:', url);
        return url; // GS 경로 반환
      } catch (error) {
        console.error('GS 경로 가져오기 실패:', error);
      }
    }
*/
//axios.defaults.withCredentials = true;

async function getData() {
  try {
    //응답 성공
    clearRecipe();

    console.log("model:", model);
    //const response = await axios.get('https://34.54.185.40:80/',
    const response = await axios.get('https://dev-backend-app-4rb5q3coza-uc.a.run.app/',
    //const response = await axios.get('https://psc4leaveitup.p.googleapis.com/',
    //const response = await axios.get('http://10.128.0.76:80/',

    {
      params: {
        image_url: gsImageUrl,
        model: model, 
        lang : navigator.language
      }
    
    });
    console.log("xmrrh respnse = :", response);
    if(response.data.data === undefined){
      console.log("response.data");
      sendDataToParent(response.data, "rag")
    }else{
      console.log("response.data.data")
    sendDataToParent(response.data.data);
    }
  } catch (error) {
    //응답 실패
    console.error(error);
  }
}
    const handleGetRecipeClick = () => {
      sendProgressToParent();

        getData()
    };
    const [browserLanguage, setBrowserLanguage] = useState(null);

    useEffect(() => {
      setBrowserLanguage(navigator.language);
      console.log("browserLanguage:", navigator.language);
    }, []);


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Box style={{color:"blue"}} >{t('please-upload-web')}</Box>
                    <br/>
                    <br/>
                    <input type="file" accept="image/jpg, image/jpeg" onChange={handleImageUpload} name="file" />

                </Grid>


                <Grid item xs={3}>
                {imageUrl &&  
                      <div>
              
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label"><b>{t('select-model')}</b></FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="gemini-1.5-pro-001"
                            name="radio-buttons-group"
                            onChange={(event) => setModel(event.target.value)}
                          >
                            <FormControlLabel value="gemini-1.5-flash-001" control={<Radio />} label="Gemini 1.5 Flash" />
                            <FormControlLabel value="gemini-1.5-pro-001" control={<Radio /> } label="Gemini 1.5 Pro" />
                            <FormControlLabel value="rag" control={<Radio />} label={t('model-rag')} /*disabled*/ />
                          </RadioGroup>
                      </FormControl>
                      </div>
                                            
                }
                </Grid>
                <Grid item xs={2}>
                {imageUrl && <Button variant="contained" onClick={() => {
                                    handleGetRecipeClick();
                                }}>Get Recipe</Button>}
                </Grid>
                <Grid item xs={7}>
                
                <div className="col-md-6 offset-md-3">
          {/* Display the uploaded image if available */}
          {imageUrl && <img src={`${imageUrl}?w=800&h=500&fit=crop&auto=format&dpr=2 2x`}  height='320' minheight='320'   className="img-fluid" alt="Uploaded Recipe Image" />}
          {/* Otherwise, display the placeholder image */}
          {!imageUrl && !uploading && <Box style={{color:"red"}} >{t('please-upload-ask')}</Box>}
          {!imageUrl && uploading && <Box style={{color:"red"}} >{t('uploading')}</Box>}
        </div>
          
                </Grid>
                <Grid item xs={5}>
                    <ImageList sx={{ width: 400, height: 300 }} cols={2} rowHeight={145} gap={8}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                            <img
                                src={`${item.img}`}
                                loading="lazy"
                                style={{width: 195, height: 100}}
                                  onClick={() => {
                                    handleClick(item.img, item.img_gs);
                                }}
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>

            </Grid>
    </Box>
        );
}

export default SourceImage;

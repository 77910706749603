
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './navi/Navbar';
import Refrigerator from './refrigerator/Refrigerator';
import Clean from './clean/Clean';

function App() {
    

    return (
      <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Refrigerator/>}/>
          <Route path='/clean' element={<Clean/>}/>

        </Routes>
      </Router>
      </>
    );
}
export default App;
/*
import { BrowserView, MobileView } from 'react-device-detect'
import DeskTopScreen from './DeskTopScreen'; // Assuming DeskTopScreen.js is in the same directory
import MobileScreen from './MobileScreen'; // Assuming DeskTopScreen.js is in the same directory

const App = () => {
  return (
    <div className="App">

      <BrowserView>
        <DeskTopScreen />
      </BrowserView>
      <MobileView>
        <MobileScreen />
      </MobileView>
    </div>
  );
}

export default App;*/
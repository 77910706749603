import { BrowserView, MobileView } from 'react-device-detect'
import DeskTopScreen from './DeskTopScreen'; // Assuming DeskTopScreen.js is in the same directory
import MobileScreen from './MobileScreen'; // Assuming DeskTopScreen.js is in the same directory

const Refrigerator = () => {
  return (
    <div className="App">

      <BrowserView>
        <DeskTopScreen />
      </BrowserView>
      <MobileView>
        <MobileScreen />
      </MobileView>
    </div>
  );
}

export default Refrigerator;